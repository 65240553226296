import Vue from 'vue'
import VueRouter from 'vue-router'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'Home',
    component: () => import(/* webpackChunkName: "home" */ '../views/Home.vue')
  },
  {
    path: '/crm',
    name: 'Crm',
    component: () => import(/* webpackChunkName: "crm" */ '../views/Crm.vue')
  },
  {
    path: '/wx',
    name: 'Wx',
    component: () => import(/* webpackChunkName: "wx" */ '../views/Wx.vue')
  },
  {
    path: '/bi',
    name: 'Bi',
    component: () => import(/* webpackChunkName: "bi" */ '../views/Bi.vue')
  },
  {
    path: '/case',
    name: 'Case',
    component: () => import(/* webpackChunkName: "case" */ '../views/Case.vue')
  },
  {
    path: '/about',
    name: 'About',
    component: () => import(/* webpackChunkName: "about" */ '../views/About.vue')
  },
  {
    path: '/deatailA',
    name: 'DeatailA',
    component: () => import(/* webpackChunkName: "deatailA" */ '../views/Deataila.vue')
  },
  {
    path: '/deatailB',
    name: 'DeatailB',
    component: () => import(/* webpackChunkName: "deatailB" */ '../views/Deatailb.vue')
  },
  {
    path: '/deatailC',
    name: 'DeatailC',
    component: () => import(/* webpackChunkName: "deatailC" */ '../views/Deatailc.vue')
  },
  {
    path: '/deatailD',
    name: 'DeatailD',
    component: () => import(/* webpackChunkName: "deatailD" */ '../views/Deataild.vue')
  },
  // wx
  {
    path: '/deatailH',
    name: 'DeatailH',
    component: () => import(/* webpackChunkName: "deatailH" */ '../views/Deatailh.vue')
  },
  {
    path: '/deatailI',
    name: 'DeatailI',
    component: () => import(/* webpackChunkName: "deatailI" */ '../views/Deataili.vue')
  },
  {
    path: '/deatailJ',
    name: 'DeatailJ',
    component: () => import(/* webpackChunkName: "deatailJ" */ '../views/Deatailj.vue')
  },
  {
    path: '/deatailK',
    name: 'DeatailK',
    component: () => import(/* webpackChunkName: "deatailK" */ '../views/Deatailk.vue')
  },
  {
    path: '/deatailL',
    name: 'DeatailL',
    component: () => import(/* webpackChunkName: "deatailL" */ '../views/Deataill.vue')
  },
  {
    path: '/deatailO',
    name: 'DeatailO',
    component: () => import(/* webpackChunkName: "deatailO" */ '../views/Deatailo.vue')
  },
  {
    path: '/deatailP',
    name: 'DeatailP',
    component: () => import(/* webpackChunkName: "deatailP" */ '../views/Deatailp.vue')
  },
  {
    path: '/deatailQ',
    name: 'DeatailQ',
    component: () => import(/* webpackChunkName: "deatailQ" */ '../views/Deatailq.vue')
  },
  {
    path: '/deatailR',
    name: 'DeatailR',
    component: () => import(/* webpackChunkName: "deatailR" */ '../views/Deatailr.vue')
  },
  {
    path: '/deatailS',
    name: 'DeatailS',
    component: () => import(/* webpackChunkName: "deatailS" */ '../views/Deatails.vue')
  },
  {
    path: '/deatailX',
    name: 'DeatailX',
    component: () => import(/* webpackChunkName: "deatailX" */ '../views/Deatailx.vue')
  },
  {
    path: '/deatailY',
    name: 'DeatailY',
    component: () => import(/* webpackChunkName: "deatailY" */ '../views/Deataily.vue')
  },
  {
    path: '/deatailZ',
    name: 'DeatailZ',
    component: () => import(/* webpackChunkName: "deatailZ" */ '../views/Deatailz.vue')
  },
]

const router = new VueRouter({
  mode: 'history',
  base: '/',
  routes
})

export default router
