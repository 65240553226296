<template>
  <div class="menuTop">
    <img alt="80码农互助" src="../assets/logo80.png" />
    <p>码农互助</p>
    <div class="nav">
        <div v-for="(row, i) in navData" :key="i" :class="{active:currentIndex == i}" @click="currentIndex = i">{{row.label}}</div>
    </div>
    <div class="searchBox">
      案例:
      <el-select v-model="value" clearable @change="getDetail" placeholder="请选择案例" class="flex1">
        <el-option-group
          v-for="group in options"
          :key="group.label"
          :label="group.label">
          <el-option
            v-for="item in group.options"
            :key="item.value"
            :label="item.label"
            :value="item.value">
          </el-option>
        </el-option-group>
      </el-select>
    </div>
    <!-- <div class="demandBtn">
      发布需求
    </div> -->
    <img alt="15110289267" src="../assets/phone.png" />
  </div>
</template>

<script>
export default {
  name: 'MenuTop',
  data () {
      return {
        currentIndex: 0,
        navData: [{
          label: '首 页',
          value: 'Home'
        }, {
          label: 'CRM/ERP/SAAS/中后台',
          value: 'Crm'
        }, {
          label: '小程序/APP',
          value: 'Wx'
        }, {
          label: 'BI报表/大屏',
          value: 'Bi'
        }, {
          label: '经典案例',
          value: 'Case'
        }, {
          label: '关于我们',
          value: 'About'
        }],
        options: [{
          label: 'crm/erp/saas...',
          options: [{
            value: 'A',
            label: '汇流用户聚合系统',
          }, {
            value: 'B',
            label: '合思企业管理平台',
          }, {
            value: 'C',
            label: '化工园区智能管理平台',
          }, {
            value: 'D',
            label: '鲁班大牛',
          }]
        }, {
          label: '小程序/App',
          options: [{
            value: 'H',
            label: '合思商城',
          }, {
            value: 'I',
            label: '汇流直推',
          }, {
            value: 'J',
            label: '三元及递',
          }, {
            value: 'K',
            label: '闪电超市',
          }, {
            value: 'L',
            label: '美菜商城',
          }]
        }, {
          label: 'BI报表/大屏',
          options: [{
            value: 'O',
            label: '启迪园区概况',
          }, {
            value: 'P',
            label: '5G数字化生活管控平台',
          }, {
            value: 'Q',
            label: '财务科数据展示',
          }, {
            value: 'R',
            label: '新农都数据监控分析',
          }, {
            value: 'S',
            label: '工程建设作战指挥室',
          }]
        }, {
          label: '前台官网、电商案例',
          options: [{
            value: 'X',
            label: '合思商旅',
          }, {
            value: 'Y',
            label: 'gotin有大',
          }, {
            value: 'Z',
            label: '美菜官网',
          }]
        }],
        value: '',
      }
    },
    watch: {
      currentIndex(i) {
        console.log(this.$router, this.$route)
        this.$router.push({
          name: this.navData[i].value
        })
      }
    },
    methods: {
      getDetail(key) {
        this.$router.push({ name: `Deatail${key}` });
      }
    }
}
</script>

<style scoped lang="scss">
.menuTop{
    height: 62px;
    background: red;
    margin: 1px 0 0px 0;
    background-image: linear-gradient(#003982, #091F3C); // 032F67
    box-shadow: 0 2px 5px #8EA9CC;
    padding: 0 10px 0 20px;
    display: flex;
    color: white;
    align-items: center;
    text-shadow: 3px 3px 3px #000;
    p{
      font-size: 21px;
      font-weight: bold;
      padding: 0 30px 0 5px;
    }
    .nav{
      div.active{
        font-weight: bold;
        background-image: linear-gradient(#002556, #003f91);
        border: 1px solid #2d68b5;
        box-shadow: 0 0 5px 1px #000;
        opacity: 1;
        border-radius: 100px;
        font-size: 16px;
      }
      div{
        padding: 4px 13px;
        border-radius: 5px;
        cursor: pointer;
        opacity: .7;
        height: 20px;
        line-height: 20px;
      }
    }
    .demandBtn{
      cursor: pointer;
      margin: 0 15px;
    }
    .searchBox{
      display: flex;
      flex:1;
      padding: 0 10px;
      margin-left: 30px;
      align-items: center;
      height: 30px;
      border-radius: 20px;
      background-color: white;
      min-width: 200px;
      text-shadow: none;
      color: #002556;
      font-size: 12px;
      ::v-deep(.el-input__inner){
        border: none !important;
      }
    }
}
.flex1{ flex: 1; }
.nav{
  display: flex;
}

</style>
