<template>
  <div id="app">
    <MenuTop />
    <div class="page">
      <router-view/>
    </div>
    <div class="flex footer1">
      <div class="flex1">
        <p class="f18 fb mb20">关于我们</p>
        <div style="padding-right: 70px; opacity: 0.6;" class="lh25 line3">
          我们是一群由80后组成的IT开发团队，都曾在一线互联网公司任职，
          曾任职的公司有高德地图、百度、360、去哪儿网、优酷、vipKid、美菜网...
        </div>
      </div>
      <div class="flex1">
        <p class="f18 fb mb20">服务范围</p>
        <div  class="lh25 line3" style="opacity: 0.6;">
          > WEB\移动APP\交互原型设计<br />
          > UI视觉设计<br />
          > 前端开发<br />
          > 整站建设
        </div>
      </div>
      <div class="flex1">
        <p class="f18 fb mb20">联系我们</p>
        <div class="lh25 line3" style="opacity: 0.6;">
          QQ：724719285<br />
          Tel: 15110289267<br />
          Address：北京市朝阳区大望路<br />
          Work-Time：09:00至21:00
        </div>
      </div>
      <div class="flex1">
        <p class="f18 fb mb20">微信扫一扫</p>
        <img alt="定制开发，一对一服务" src="./assets/code.png">
      </div>
    </div>
    <div style="background: #0A1B31; line-height: 25px; color: #AFB1B6; padding: 20px; text-align: center">
      <p class="friendLink">友情链接: <a href="https://codeload.top/" target="_blank">码载代驾</a></p>
    交互设计 | ui设计 | 前端开发 | 整站建设<br />
    北京市朝阳区大望路<br />
    QQ：724719285  Tel: 15110289267<br />
    @2020-2030 版权所有<br />ICP备案/许可证号：冀ICP备2023013479号
    </div>
    <Popover
      placement="left"
      width="500"
      v-model="visible"
      trigger="click">
      <el-form :model="ruleForm" :rules="rules" ref="ruleForm" label-width="100px" class="demo-ruleForm">
        <el-form-item label="您的名字" prop="name">
          <el-input v-model="ruleForm.name"></el-input>
        </el-form-item>
        <el-form-item label="联系电话" prop="mobile">
          <el-input v-model="ruleForm.mobile"></el-input>
        </el-form-item>
        <el-form-item label="您的需求" prop="description">
          <el-input type="textarea" v-model="ruleForm.description" :rows="5"></el-input>
        </el-form-item>
        <el-form-item>
          <el-button type="primary" @click="submitForm('ruleForm')"  size="mini">立即提交</el-button>
        </el-form-item>
      </el-form>
      <Button icon="el-icon-chat-dot-square" slot="reference" class="fixed-icon" circle></Button>
    </Popover>
  </div>
</template>

<script>
import MenuTop from '@/components/MenuTop.vue'
import { Popover, Form, FormItem, Button, Input } from 'element-ui';
import axios from 'axios'

export default {
  name: 'App',
  components: {
    MenuTop,
    Popover,
    Button,
    ElInput: Input,
    ElButton: Button,
    ElForm: Form,
    ElFormItem: FormItem,
  },
  methods: {
    backup: (e) => {
      e.stopPropagation();
      console.log('backup')
    },
    submitForm(formName) {
      this.$refs[formName].validate((valid) => {
        if (valid) {
          axios({
            method: 'post',
            url: '/management-api/www/contact/',
            data: {
              ...this.ruleForm
            }
          });
        } else {
          console.log('error submit!!');
          return false;
        }
      });
    },
    checkMoblie: (rule, value, cb) => {
      // 验证手机号的正则表达式
      const regMobile =
        /^1(3[0-9]|4[01456879]|5[0-35-9]|6[2567]|7[0-8]|8[0-9]|9[0-35-9])\d{8}$/
      if (regMobile.test(value)) {
        // 合法的手机号
        return cb()
      }
      cb(new Error('请输入正确的手机号'))
    }
  },
  data() {
      return {
        ruleForm: {
          name: '',
          mobile: '',
          description: ''
        },
        rules: {
          name: [
            { required: true, message: '请填写您的名字', trigger: 'blur' },
            { min: 3, max: 5, message: '长度在 3 到 5 个字符', trigger: 'blur' }
          ],
          mobile: [
            { required: true, message: '请正确填写您的手机号', trigger: 'blur' },
            { validator: this.checkMoblie, trigger: 'blur' },
          ]
        },
        visible: false
      };
    }
}
</script>

<style lang="scss">
*, html, body {padding: 0; margin:0; font-size: 14px; line-height: 20px; }
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: #333;
  border-top: 1px solid #2E6DC0;
  min-width: 1300px;
}
.w-full{
  width: 100%;
}

.lh25{ line-height: 25px;}

.footer1{
  background-color: #102543;
  color: white;
  padding: 50px 100px;
}
.title{ font-weight: bold; font-size: 18px;}
.line1{ position: relative; border-bottom: 3px solid #E1E1E1; padding-bottom: 20px; margin-bottom: 20px; margin-top: 10px;}
.line1:after{
  content: '';
  display: flex;
  width: 10px;
  height: 10px;
  border-radius: 10px;
  border: 3px solid #E1E1E1;
  background-color: #fff;
  position: absolute;
  bottom: -9px;
  left: 50%;
  margin-left: -7px;
}
.line2:before{
  content: '>';
  color: #E1E1E1;
  font-size: 30px;
  position: absolute;
  bottom: -11px;
  right: -5px;
  margin-left: -5px;
}

.line3{position: relative;}
.line3:before{
  content: '';
  width: 30%;
  height: 3px;
  background: gray;
  position: absolute;
  top: -10px;
  left: 0px;
}

.tc{ text-align: center;}
.tl{ text-align: left;}
.tr{ text-align: right;}

.bgGray{ background-color: #F5F6F7;}
.bgWhite{ background-color: white;}
.bgBlue{ background-color: #F5F9FE;}
.bgBlack{ background: #061c3b;}

.textWhite{ color: white; }
.shadow5{ box-shadow: 0 0 10px 2px #eee,0 0 10px 2px #F5F6F7  inset; border:1px solid white; }
.shadow4{ box-shadow: 0 3px 10px 1px #98c6ff; border:2px solid white; overflow: hidden; }
.shadow3{ background: #fff; border-radius: 3px; padding: 1px 10px; }

.pointer{ cursor: pointer;}

.m-w200{min-width: 200px;}

.br2{ border-bottom: 2px solid #fff;}
.flex{display: flex;}
.flex1{ flex: 1;}
.flexC{ flex-direction: column; }
.flexR{ flex-direction: row; }
.itemC{ align-items: center;}
.rowC{ justify-content: center;}

.br5{ border-radius: 5px; }
.br10{ border-radius: 10px; }
.br20{ border-radius: 20px; }
.br30{ border-radius: 30px; }

.fb{ font-weight: bold; }
.f12{ font-size: 12px; }
.f14{ font-size: 14px; }
.f16{ font-size: 16px; }
.f18{ font-size: 18px; }
.f20{ font-size: 20px; }
.f22{ font-size: 22px; }
.f24{ font-size: 24px; }
.f36{ font-size: 36px; }

.mt1{margin-top:1px;}
.m10{ margin: 10px;}
.mt10{ margin-top: 10px;}
.mb10{ margin-bottom: 10px;}
.ml10{ margin-left: 10px;}
.mr10{ margin-right: 10px;}
.m20{ margin: 20px;}
.mt20{ margin-top: 20px;}
.mb20{ margin-bottom: 20px;}
.ml20{ margin-left: 20px;}
.mr20{ margin-right: 20px;}

.p2{ padding: 2px;}
.p10{ padding: 10px;}
.p50{ padding: 50px;}
.pt10{ padding-top: 10px;}
.pb10{ padding-bottom: 10px;}
.pl10{ padding-left: 10px;}
.pr10{ padding-right: 10px;}
.p20{ padding: 20px;}
.pt20{ padding-top: 20px;}
.pb20{ padding-bottom: 20px;}
.pl20{ padding-left: 20px;}
.pr20{ padding-right: 20px;}

.flex-align-items{
  align-items: center;
  flex-wrap: nowrap;
  justify-content: center;
  align-content: space-around;
}
.maxwidth300 { max-width: 25%; margin: 8px 50px;}
.maxwidth300 .tr img {
  width: 60px;
  margin-top: 12px;
  margin-right: 15px;
}

.over-hidden{
  overflow: hidden;
}

.transform {
  transform: translate(0, 0);
  transition: all 300ms ease-in 0s;
}

.transform[transform~='right'][started~=true] {
  transform: translate(806px, 10px);
}

.transform[transform~='left'][started~=true] {
  transform: translate(-806px, 10px);
}

.fixed-icon {
  position: fixed;
  right: 50px;
  bottom: 50px;
  z-index: 100;
  animation: twinkle 0.6s infinite alternate;
  :hover {
    animation: twinkle 0.5s 0 0;
  }
}
@keyframes twinkle {
  0%{
    background-color: #c4d4f8;// opacity: .0.8;
    // box-shadow: 0px 0px 10px 2px #c4d4f8;
  }
  100%{
    background-color: #ffffff;
    // box-shadow: 0px 0px 10px 2px #fff;
  }
}
.friendLink{
  padding: 10px;
}
.friendLink a{
  color: #AFB1B6;
}
</style>
